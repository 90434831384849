.logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

body {
  background-color: #f0f2f5;
}

.site-layout-sub-header-background {
  color: white;
  background: #575756;
  padding: 20px;
  font-size: 18px;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: black;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: black;
}

.ant-modal-header {
  background-color: #575756;
  color: white;
}
.ant-modal-close-x {
  color: white;
}
.ant-modal-title {
  color: white;
}

.ant-form-item-label {
  padding: 0;
}

.ant-form-item-explain,
.ant-form-item-extra {
  font-size: 12px;
}

.react-resizable {
  position: relative;
  background-clip: padding-box;
}

.react-resizable-handle {
  position: absolute;
  right: -5px;
  bottom: 0;
  z-index: 2;
  width: 10px;
  height: 100%;
  cursor: col-resize;
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
  visibility: hidden;
}

.row-dragging .drag-visible {
  visibility: visible;
}
/* 
.ant-form-vertical .ant-form-item-label {
  padding: 0 0 3px;
}

.ant-table-cell {
  vertical-align: super;
}

.ant-table.ant-table-small .ant-table-thead .ant-table-filter-column {
  margin: 0;
} */

.ant-table-sticky-holder {
  z-index: 2 !important;
}
